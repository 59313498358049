<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
      <div class="divide-y-2 divide-gray-200">
        <div class="lg:grid lg:grid-cols-3 lg:gap-8">
          <h2 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">Get in touch</h2>
          <div class="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">Phone</h3>
              <dl class="mt-2 text-base text-gray-500">
                
                <div class="mt-1">
                  <dt class="sr-only">Phone number</dt>
                  <dd><a href="tel:519-752-8478" class="with-underline">519-752-8478</a></dd>
                </div>
              </dl>
            </div>
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">Email</h3>
              <dl class="mt-2 text-base text-gray-500">
                <div>
                  <dt class="sr-only">Email</dt>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">Email</dt>
                  <dd><a href="mailto:operations@hewings.ca" class="with-underline">operations@hewings.ca</a></dd>
                </div>
              </dl>
            </div>
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">Fax</h3>
              <dl class="mt-2 text-base text-gray-500">
                <div>
                  <dt class="sr-only">Fax</dt>
                </div>
                <div class="mt-1">
                  <dd>519-751-7762</dd>
                </div>
              </dl>
            </div>
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">Visit Us</h3>
              <dl class="mt-2 text-base text-gray-500">
                <div>
                  <dt class="sr-only">Visit Us</dt>
                </div>
                <div class="mt-1">
                  <dd><address class="not-italic">35 Sharp Rd<br/>Brantford ON N3T 5L8</address></dd>
                </div>
              </dl>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
import { MailIcon, PhoneIcon } from "@heroicons/vue/outline";
export default {
  name: "Contact Section",
  components: {
    MailIcon,
    PhoneIcon,
  },
};
</script>
